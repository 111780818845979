import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";

export default function IndexPage() {
  return (
    <div>
      <div id="wrapper">
        <Header />
        <main className="sp-content">
          <div className="main-hero mb80">
            <div className="hero-1">
              <img src="img/rml_logo4.png" alt="ロイヤルミハランド" />
            </div>
            <div className="hero">
              <a href="messege.html">
                <img src="img/miha_2.png" alt="ミハランド王" width={112} />
              </a>
            </div>
          </div>
          <div className="pro-inner mb40">
            <div className="box-mat">
              <div className="box">
                <h2 className="t-area aC mb10">
                  <span>プロローグ</span>
                </h2>
                <p className="blue aC mb20">
                  <small>〜Prologue〜</small>
                </p>
                <div className="pro_wrapper">
                  <p className="mb10">
                    長崎県三原にある『三原庭園』。
                    <br />
                    英国のチェルシーフラワーショーで11回金賞を受賞した庭園デザイナー石原和幸が手掛け、
                    <br className="hyd" />
                    素晴らしい景観を作り出している。
                    <br />
                    <br />
                    しかし三原庭園は単なる庭に留まらない。周辺すべてを巻き込みながらひとつの『国』を作るため、
                    <br className="hyd" />
                    リアルに開発が続けられ日々拡大している。
                    <br className="hyd" />
                    和庭園・洋庭園・盆栽小路・カフェ・バー・農園・テナント・ホテル…
                    <br />
                    さまざまなスポットが集まったエリアを『
                    <span className="red">ロイヤルミハランド</span>
                    』と名付けた。
                    <br />
                    <br />
                    このWEBサイトは『
                    <span className="red">ロイヤルミハランド</span>
                    』をさらに大きな国にしていくため、
                    <br className="hyd" />
                    全世界からバーチャル国民を募集するために立ち上がったものである。
                  </p>
                  <br />
                  <br />
                  <h2 className="t-area aC mb20">
                    <span>エリアマップ</span>
                  </h2>
                  <p className="blue mb20">
                    和庭園・洋庭園・盆栽小路・カフェ・バー・農園・テナント・ホテル…
                    <br />
                    さまざまなスポットが集まっています。
                  </p>
                  <div className="inner w100p mb80">
                    <ul className="map-nav-new clearfix">
                      <li>
                        <img src="img/sp_1.jpg" alt="三原の泉" />
                        <div>
                          <a href="area_1.html">三原の泉</a>
                        </div>
                      </li>
                      <li>
                        <img src="img/sp_2.jpg" alt="三原の楽園" />
                        <div>
                          <a href="area_2.html">三原の楽園</a>
                        </div>
                      </li>
                      <li>
                        <img src="img/sp_3.jpg" alt="王宮" />
                        <div>
                          <a href="area_3.html">王宮</a>
                        </div>
                      </li>
                      <li>
                        <img src="img/sp_4.jpg" alt="Bar MIHARA GARDEN" />
                        <div>
                          <a href="area_4.html">Bar MIHARA GARDEN</a>
                        </div>
                      </li>
                      <li>
                        <img src="img/sp_5.jpg" alt="国王の宝物" />
                        <div>
                          <a href="area_5.html">国王の宝物</a>
                        </div>
                      </li>
                      <li>
                        <img src="img/sp_6.jpg" alt="バッキンガム宮殿" />
                        <div>
                          <a href="area_6.html">バッキンガム宮殿</a>
                        </div>
                      </li>
                      <li>
                        <img src="img/sp_7.jpg" alt="スーベニア" />
                        <div>
                          <a href="area_7.html">スーベニア</a>
                        </div>
                      </li>
                      <li>
                        <img src="img/sp_8.jpg" alt="果物畑" />
                        <div>
                          <a href="area_8.html">果物畑</a>
                        </div>
                      </li>
                      <li>
                        <img src="img/sp_9.jpg" alt="三原ファーム" />
                        <div>
                          <a href="area_9.html">三原ファーム</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* /inner */}
                  <div
                    style={{ background: "url(img/bb.jpg)", padding: "20px" }}
                  >
                    <h2 className="t-area aC mb10">
                      <span>ミハランド国王</span>
                    </h2>
                    <p className="aC king">
                      <img
                        src="img/king_anime_sample.gif"
                        alt="ミハランド国王"
                        width={210}
                      />
                    </p>
                    <p className="aC mb20">
                      <a href="messege.html" className="btn t18 w280">
                        国王からのメッセージ
                        <i className="material-icons">chevron_right</i>
                      </a>
                    </p>
                  </div>
                </div>
                {/* /box */}
              </div>
              {/* /box-mat */}
            </div>
            <div className="inner">
              <div className="aC mb60">
                <a href="recruitment.html">
                  <picture>
                    <source
                      media="(max-width: 480px)"
                      srcSet="img/bnr_rec_sp.png"
                    />
                    <img src="img/bnr_rec.png" width alt="国民募集" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className="p-top">
            <a href="#top">
              <img src="img/page_up.png" alt="" width={40} />
            </a>
          </div>
          <div className="footer-box">
            <div className="inner">
              <ul className="footer-nav-1 mb10">
                <li>
                  <a href="/">ホーム</a>
                </li>
                <li>
                  <ul className="footer-nav-2">
                    <li>
                      <a href="area_1.html">三原の泉</a>
                    </li>
                    <li>
                      <a href="area_2.html">三原の楽園</a>
                    </li>
                    <li>
                      <a href="area_3.html">王宮</a>
                    </li>
                    <li>
                      <a href="area_4.html">Bar MIHARA GARDEN</a>
                    </li>
                    <li>
                      <a href="area_5.html">国王の宝物</a>
                    </li>
                    <li>
                      <a href="area_6.html">バッキンガム宮殿</a>
                    </li>
                    <li>
                      <a href="area_7.html">占いの館</a>
                    </li>
                    <li>
                      <a href="area_8.html">果物畑</a>
                    </li>
                    <li>
                      <a href="area_9.html">三原ファーム</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul className="footer-nav-2">
                    <li>
                      <a href="messege.html">国王からのメッセージ</a>
                    </li>
                    <li>
                      <a href="about.html">ロイヤルミハランドとは</a>
                    </li>
                    <li>
                      <a href="recruitment.html">国民募集</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="http://www.kaza-hana.jp/">石原和幸デザイン研究所</a>
                </li>
              </ul>
            </div>
          </div>
          <address className="aC t10 white">© ISHIHARA INC.</address>
        </footer>
        {/* /footer*/}
      </div>
      {/* /#wrapper */}
    </div>
  );
}
